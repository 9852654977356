import { debounce } from 'radash'
import { useGeocode } from './useGeocode'
import { useStores } from './useStores'
import { useMapMarker } from './useMapMarker'
import type GeolocationPosition from '~/components/store-finder/IGeolocationPosition'

export const useStoreFinder = () => {
  onUnmounted(() => resetSearch())

  const router = useRouter()
  const localePath = useFimLocalePath()
  const { resetMarkers } = useMapMarker()

  const { data: geocodeData, fetch } = useGeocode()
  const { fetch: fetchStores, search, searchOption, fetching } = useStores()

  const location = ref<GeolocationPosition>()

  const resetSearch = () => {
    search.value = ''
    resetMarkers()
  }

  const getLocation = (): Promise<GeolocationPosition> =>
    new Promise((resolve, reject) => {
      if (!('geolocation' in navigator)) {
        reject(new Error('Geolocation is not available.'))
      }

      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve(pos)
        },
        (err) => {
          reject(err)
        },
      )
    })

  const onLocate = async () => {
    try {
      location.value = await getLocation()
      await fetch({
        lat: location.value.coords.latitude,
        lng: location.value.coords.longitude,
      })
      await fetchStoresMethod()
      search.value = geocodeData.value?.results?.[0]?.formatted_address ?? ''
    } catch (e: any) {
      e.value = e.message

      console.log(e)
    }
  }

  const onSearch = async () => {
    try {
      await fetch({ search: search.value })
      await fetchStoresMethod()
      router.push({
        path: localePath({ name: routeList.stores.name }),
        query: {
          location: encodeURI(search.value as string),
          ...(searchOption.value && { services: searchOption.value }),
        },
      })
    } catch (e: any) {
      e.value = e.message

      console.log(e)
    }
  }

  const fetchStoresMethod = async () => {
    await fetchStores({
      lat: geocodeData.value?.results[0]?.geometry?.location
        ?.lat as unknown as number,
      lng: geocodeData.value?.results[0]?.geometry?.location
        ?.lng as unknown as number,
    })
  }

  return {
    search,
    onLocate: debounce({ delay: 100 }, onLocate),
    onSearch: debounce({ delay: 100 }, onSearch),
    fetching,
    resetSearch,
  }
}
