<template>
  <div
    v-if="isMultilanguageShop"
    class="flex grow items-center gap-2 pb-6 sm:pb-0"
  >
    <div class="whitespace-nowrap">{{ $t('lang_switcher.label') }}:</div>
    <div class="flex gap-2">
      <button
        v-for="lang in shopLanguages"
        :key="lang.code"
        class="rounded px-2 py-1 text-sm font-medium uppercase leading-none"
        :class="[
          lang.isActive ? 'bg-white text-black' : 'bg-[#5c5955] text-white',
        ]"
        :disabled="lang.isActive"
        :aria-label="lang.label"
        @click="onClick(lang.code)"
      >
        {{ lang.shortLabel }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
const { shopLanguages, isMultilanguageShop, switchLanguage } = useLocale()
const onClick = (code: string) => {
  switchLanguage(code)
}
</script>
